export function downloadData(filename, data) {
  let csv = Object.keys(data[0]) + "\r\n";
  const jsonObject = JSON.stringify(data);
  const array =
    typeof jsonObject !== "object" ? JSON.parse(jsonObject) : jsonObject;
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (
        array[i][index] &&
        !typeof array[i][index] === "number" &&
        !isNaN(array[i][index]) &&
        array[i][index].includes(",")
      )
        array[i][index] = '"' + array[i][index] + '"';
      if (line !== "") line += ",";
      line += '"' + array[i][index] + '"';
    }
    csv += line + "\n";
  }

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
